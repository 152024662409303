<template>
	<f7-page class="softgray" name="home">
		<f7-appbar
			:bg-color="
				elvalor.global_status == 'green'
					? 'blue'
					: elvalor.ri && elvalor.exv
					? 'green'
					: 'gray'
			"
		>
			<div class="left clp-largefont">
				{{
					elvalor.global_status == "red" && elvalor.rr
						? $t("resultado.requiereRefuerzo")
						: elvalor.global_status == "red" && elvalor.ri && !elvalor.vj
						? ""
						: elvalor.tmp
						? $t("resultado.paseMovilidad") + " " + $t("resultado.temporal")
						: $t("resultado.paseMovilidad")
				}}
			</div>
			<div class="right">
				<font-awesome-icon
					v-if="elvalor.global_status == 'green'"
					class="fa-lg"
					icon="thumbs-up"
				></font-awesome-icon>
				<span class="viajero" v-else-if="elvalor.vj"
					><font-awesome-icon
						class="fa-lg"
						icon="globe-americas"
					></font-awesome-icon>
					{{ $t("resultado.viajero") }}</span
				>
				<span class="viajero" v-else-if="elvalor.bl"
					><font-awesome-icon
						class="fa-lg"
						icon="hand-paper"
					></font-awesome-icon>
					{{ $t("resultado.bloqueo") }}</span
				>
				<span class="viajero" v-else-if="elvalor.ai"
					><font-awesome-icon
						class="fa-lg"
						icon="hand-paper"
					></font-awesome-icon>
					{{ $t("resultado.aislamiento") }}</span
				>
				<span class="viajero" v-else-if="elvalor.rr"
					><font-awesome-icon class="fa-lg" icon="syringe"></font-awesome-icon>
					{{ $t("resultado.refuerzo") }}</span
				>
				<span class="viajero" v-else-if="elvalor.ri && elvalor.exv"
					><font-awesome-icon
						class="fa-lg"
						icon="plane-arrival"
					></font-awesome-icon
				></span>
				<font-awesome-icon
					v-else
					class="fa-lg"
					icon="hand-paper"
				></font-awesome-icon>
			</div>
		</f7-appbar>
		<f7-block
			v-if="elvalor.ex"
			:bg-color="
				elvalor.global_status == 'green'
					? 'blue'
					: elvalor.ri && elvalor.exv
					? 'green'
					: 'gray'
			"
			class="subappbar text-align-center"
		>
			<h2 class="text-color-white text-align-center esuppercase">
				{{
					elvalor.global_status == "green"
						? $t("resultado.habilitado")
						: elvalor.ri && elvalor.exv
						? $t("resultado.aptoIngreso")
						: !elvalor.exv
						? $t("resultado.noAptoIngreso")
						: $t("resultado.noHabilitado")
				}}
				{{ elvalor.c ? "-DEV" : "" }}
			</h2>
		</f7-block>
		<f7-block
			v-else
			:bg-color="elvalor.global_status == 'green' ? 'blue' : 'gray'"
			class="subappbar text-align-center"
		>
			<h1 class="text-color-white text-align-center esuppercase">
				{{
					elvalor.global_status == "green"
						? $t("resultado.habilitado")
						: $t("resultado.noHabilitado")
				}}
				{{ elvalor.c ? "-DEV" : "" }}
			</h1>
		</f7-block>
		<f7-block class="conten no-padding">
			<f7-card class="pullmeup text-align-center">
				<img
					v-if="false"
					:src="elvalor.photourl || `../static/heros/avatar.jpg`"
					class="useravatar"
				/>
				<f7-block
					class="text-align-center no-margin-top margin-bottom-half identidad"
					><div>{{ elvalor.identidad }}</div>
					<div class="apellidos">{{ elvalor.pacienteAps }}</div>
					<div class="nombres">
						{{ elvalor.pacienteNm }}
					</div></f7-block
				>
			</f7-card>
			<f7-block v-if="permiteScanner" class="reescanea no-margin-vertical">
				<f7-button
					large
					outline
					color="purple"
					@click="$emit('cierraResultYAbreCamara')"
					raised
				>
					<font-awesome-icon
						class="fa-lg margin-right-half"
						icon="qrcode"
					></font-awesome-icon
					>{{ $t("resultado.escanearNuevoPase") }}</f7-button
				>
			</f7-block>
			<f7-block
				v-if="elvalor.global_status == 'green' && !elvalor.tmp"
				class="explicax"
			>
				<div v-html="$t('resultado.habilitadoInfo')"></div>
			</f7-block>
			<f7-block
				v-else-if="elvalor.global_status == 'green' && elvalor.tmp"
				class="explicax"
			>
				<div v-html="$t('resultado.paseTempHabilitado')"></div>
			</f7-block>
			<f7-block
				v-else-if="elvalor.global_status == 'red' && elvalor.ri && elvalor.exv"
				class="explicaIngreso"
			>
				<div v-html="$t('resultado.explicoIngreso')"></div>
			</f7-block>
			<f7-block
				v-else-if="elvalor.global_status == 'red' && elvalor.ex && !elvalor.exv"
				class="explicaIngreso"
			>
				<div v-html="$t('resultado.explicoNoIngreso')"></div>
			</f7-block>
			<f7-block v-else-if="elvalor.tmp" class="explicax">
				<div v-html="$t('resultado.paseTempNoHabilitado')"></div>
			</f7-block>
			<f7-block v-else class="explicax">
				<div v-html="$t('resultado.noHabilitadoInfo')"></div>
			</f7-block>
			<f7-block class="text-align-center gobcllog">
				<f7-row>
					<f7-col><img src="../static/logos/gobsmall.jpg" /></f7-col>
					<f7-col><img src="../static/logos/minsalsmall.jpg" /></f7-col>
				</f7-row>
			</f7-block>
		</f7-block>
	</f7-page>
</template>
<script>
import { f7 } from "framework7-vue";
export default {
	props: { elvalor: Object, permiteScanner: Boolean },
	data(){
		return{
			popupvencimiento: false,
		};
	},
	mounted(){
		if(this.elvalor.popUp){
			f7.dialog.alert(`${this.$t('resultado.avisoRefuerzo1')} <strong>${this.elvalor.dias}</strong> ${this.$t('resultado.avisoRefuerzo2')}`, this.$t('resultado.atencion'))
		}
	}
};
</script>
<style>
.bordabajo li {
	border-bottom: 1px solid #cecece !important;
}
.softgray {
	background: #f6f7f9;
	position: absolute;
}
.clp-largefont {
	font-size: 15px;
	font-weight: 500;
}
.subappbar {
	height: 90px;
	margin-top: 0px !important;
}
.useravatar {
	border-radius: 4px;
	width: 50%;
	margin: 0 auto !important;
}
.pullmeup {
	position: relative;
	top: -70px;
	z-index: 999999;
}
.conten {
	max-width: 400px;
	margin: 0 auto;
}

.identidad {
	font-size: 24px;
	font-weight: 500;
}
.apellidos {
	font-size: 20px;
	font-weight: 500;
}
.nombres {
	font-size: 20px;
	font-weight: 400;
}
.explicax {
	top: -60px;
	background: #fff0a0;
	padding: 18px;
	font-size: 12px;
	margin: 10px;
	text-align: center;
}
.explicaIngreso {
	top: -60px;
	background: #fff0a0;
	padding: 18px;
	font-size: 15px;
	margin: 10px;
	text-align: center;
}
.reescanea {
	top: -69px;
}
.gobcllog {
	top: -60px;
}
.esuppercase {
	text-transform: uppercase !important;
}
.button.forzaradiox {
	border-radius: 0px 0px 4px 4px !important;
}

.vigencia-popup{

}
</style>
