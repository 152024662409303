<template>
	<f7-page :class="`mylogin back${bg} text-align-center`">
		<f7-progressbar v-if="cargando" infinite></f7-progressbar>
		<f7-navbar subtitle="V 2.5.13" :title="$t('home.escaner')">
			<f7-nav-right>
				<language-selector></language-selector>
			</f7-nav-right>
		</f7-navbar>
		<f7-toolbar class="latoolbar" position="bottom">
			<img class="dedos" src="../static/logos/banner_mevacuno.png" />
		</f7-toolbar>
		<f7-block v-if="cargando" class="suparmargtop"
			><f7-preloader></f7-preloader><br />{{ $t("commons.cargando") }}</f7-block
		>
		<template v-else>
			<template v-if="isAnonymous">
				<f7-block
					class="bannerxsup no-margin-vertical padding text-align-center"
					>{{ $t("home.claveUnica") }}</f7-block
				>
				<f7-block><clave-unica class="margin-top"></clave-unica></f7-block>
				<f7-block>{{ $t("home.escanearSinLogear") }}</f7-block>
			</template>
			<f7-block v-if="permiteScanner" class="no-margin-vertical">
				<f7-block v-if="iniciaQRStream && !scannerOpened"
					><f7-preloader></f7-preloader
				></f7-block>
				<f7-button
					v-else
					:class="{ forza_margentop: !isAnonymous }"
					large
					outline
					color="purple"
					@click="preAbrirCamara()"
				>
					<font-awesome-icon
						class="fa-lg margin-right-half"
						icon="qrcode"
					></font-awesome-icon
					>{{ $t("home.escanearPase") }}</f7-button
				>
			</f7-block>
			<f7-block v-if="!isAnonymous">
				<f7-button @click="logmeout()" large>{{
					$t("commons.cerrarSesion")
				}}</f7-button>
			</f7-block>
		</template>

		<f7-popup
			class="scanner-popup"
			:opened="scannerOpened"
			@popup:closed="postCloseScan()"
		>
			<f7-page>
				<f7-navbar :title="$t('home.encuadreQR')">
					<f7-nav-right>
						<f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
					</f7-nav-right>
				</f7-navbar>
				<f7-block class="no-margin no-padding">
					<f7-block v-if="cargandoScanner" class="text-align-center">
						<f7-preloader></f7-preloader><br />
						{{ $t("home.iniciandoEscaner") }}
					</f7-block>

					<qrcode-stream
						v-if="iniciaQRStream"
						@init="onInit"
						@decode="onDecode"
					/>
					<f7-block v-if="!cargandoScanner" class="text-align-center">{{
						$t("home.enfoque")
					}}</f7-block>
				</f7-block>
			</f7-page>
		</f7-popup>

		<f7-popup
			class="resultado-popup"
			:opened="popupresulter"
			@popup:closed="popupresulter = false"
		>
			<resultado
				v-if="popupresulter"
				:elvalor="elvalor"
				@cierraResultYAbreCamara="cierraResultYAbreCamara"
				:permiteScanner="permiteScanner"
			></resultado>
		</f7-popup>
	</f7-page>
</template>
<script>
import LanguageSelector from "../components/LanguageSelector.vue";

import claveUnica from "../components/claveUnica.vue";
import resultado from "../components/resultado.vue";

import { QrcodeStream } from "qrcode-reader-vue3";
import { f7, f7ready } from "framework7-vue";
import firebase from "firebase/app";

export default {
	components: { claveUnica, QrcodeStream, resultado, LanguageSelector },
	data() {
		return {
			bg: 1,
			cargando: true,
			scannerOpened: false,
			iniciaQRStream: false,
			cargandoScanner: true,
			clp_camperm: false,
			permiteScanner: false,
			popupresulter: false,
			isAnonymous: true,
			elvalor: {},
		};
	},
	computed: {
		esdev() {
			let devEntornos = ["scan-test.mevacuno.cl", "localhost"];
			return devEntornos.includes(window.location.hostname);
		},
	},
	mounted() {
		f7ready((f7) => {
			this.primeraFuncion();
		});
	},
	methods: {
		postCloseScan() {
			this.scannerOpened = false;
			this.iniciaQRStream = false;
		},
		primeraFuncion() {
			this.procesa();
			this.clp_camperm = localStorage.getItem("clp_camperm") || null;
			if (
				"mediaDevices" in navigator &&
				"getUserMedia" in navigator.mediaDevices
			) {
				this.permiteScanner = true;
			}
			firebase.auth().onAuthStateChanged((user) => {
				if (user) {
					this.isAnonymous = user.isAnonymous;
				} else {
					this.isAnonymous = true;
				}
			});
		},
		logmeout() {
			return firebase
				.auth()
				.signOut()
				.then(() => {
					console.log("Sign-out successful");
					f7.dialog.preloader(this.$t("home.cerrandoSesion"));
					let cualCallBack = `https://${window.location.hostname}`;
					window.location.href = `https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect=${encodeURIComponent(
						cualCallBack
					)}`;
					setTimeout(() => {
						f7.dialog.close();
					}, 2000);
				})
				.catch((error) => {
					f7.dialog.close();
					console.log(error, "error logging out");
					f7.dialog.alert(error.message, error.code);
				});
		},
		cierraResultYAbreCamara() {
			this.popupresulter = false;
			this.preAbrirCamara();
		},
		async llamaLegacy({ a, b, c, f, w, ext, type, ser, mrz, run, tmp, pni }) {
			try {
				let cual = 'scannQR';
				let requesta = firebase.functions().httpsCallable(cual);
				let result = await requesta({
					a,
					b,
					c,
					f,
					w,
					ext,
					type,
					ser,
					mrz,
					run,
					tmp,
					pni
				});
				let daouts = result.data;
				if (daouts.error) {
					if (daouts.error.message === "ATTEMPT FEA") {
						throw new Error(this.$t("home.errorQRcorruptoFEA"));
					} else if (daouts.error.message === "ATTEMPT") {
						throw new Error(this.$t("home.errorQRcorrupto"));
					} else {
						throw new Error(daouts.error.message);
					}
				}
				this.cargando = false;
				//console.log(daouts.payload)
				if (daouts.payload.url) {
					f7.views.main.router.navigate("/visor/", {
						props: {
							url: daouts.payload.url,
							vigente: daouts.payload.vigente,
						},
					});
				} else if (daouts.payload.global_status) {
					f7.dialog.close();
					daouts.payload.c = parseInt(c);
					this.elvalor = Object.assign({}, daouts.payload);
					this.popupresulter = true;
				} else {
					f7.dialog.close();
					f7.dialog.alert(
						`${daouts.payload.identidad}: ${daouts.payload.completado}`,
						`${c == "1" ? "DESARROLLO, No valido para" : "Estado"} ${
							daouts.payload.nombre
						}`
					);
				}

				const isQRLegacy = a && b && c;

				if (isQRLegacy) {
					f7.dialog.alert(
						this.$t("home.advertenciaQRText"),
						this.$t("home.advertenciaQRTitle")
					);
				}
			} catch (error) {
				f7.dialog.close();
				this.cargando = false;
				console.log(error);
				f7.dialog.alert(error.message, error.code || "Error en QR");
			}
		},
		async procesa() {
			try {
				this.cargando = true;
				let url = new URL(window.location.href);
				let a = url.searchParams.get("a");
				let b = url.searchParams.get("b");
				let c = url.searchParams.get("c");
				let w = url.searchParams.get("w");
				let f = url.searchParams.get("f");
				let code = url.searchParams.get("code");
				let state = url.searchParams.get("state");
				let type = url.searchParams.get("type");
				let ser = url.searchParams.get("serial");
				let mrz = url.searchParams.get("mrz");
				let run = url.searchParams.get("RUN");
				let pni = url.searchParams.get("pni");
				window.history.replaceState({}, document.title, "/");
				if (code && state) {
					let mystate = localStorage.getItem("clp_state");
					if (mystate != state) {
						throw new Error(this.$t("errors.stateSolicitud"));
					}
					let logclaveunic = firebase
						.functions()
						.httpsCallable("loginClaveUnica_scan");
					let result = await logclaveunic({
						dev: this.esdev,
						code,
						state,
					});
					let daouts = result.data;
					if (daouts.error) {
						throw new Error(
							`${this.$t("errors.verificarIdentidadClaveUnica")}: ${
								daouts.error.message
							}`
						);
					}
					await firebase.auth().signInWithCustomToken(daouts.payload);
					//aqui terminaria este flujo
					this.cargando = false;
				}
				//vere si viene alguna request
				else if ((a && b) || f || w || (type && run) || pni) {
					//si no esta logeado, forzar anon
					// const user = firebase.auth().currentUser;
					// if (!user) {
					//   await firebase.auth().signInAnonymously();
					// }
					// //hacer el request que corresponda
					// if(a && b){
					//   await this.llamaLegacy({a,b,c});
					// }
					// else if(f){
					//    await this.llamaLegacy({f,c});
					// }
					//  throw new Error('Debe escanear el QR utilizando el scanner de esta app');
					this.cargando = false;
					f7.dialog.alert(
						this.$t("home.debeEscanear"),
						this.$t("home.escaneoPase")
					);
				} else {
					this.cargando = false;
				}
			} catch (error) {
				f7.dialog.close();
				this.cargando = false;
				console.log(error);
				f7.dialog.alert(error.message, error.code || "error");
			}
		},
		preAbrirCamara() {
			if (!this.clp_camperm) {
				return f7.dialog.confirm(
					this.$t("home.accesoCamaraMsg"),
					this.$t("home.accesoCamaraTitle"),
					() => {
						this.iniciaQRStream = true;
					}
				);
			} else {
				this.iniciaQRStream = true;
			}
		},
		async onInit(promise) {
			// show loading indicator
			try {
				const { capabilities } = await promise;

				// successfully initialized
				// console.log('inizializado OK')
			} catch (error) {
				let errorLegible = "Scanner no disponible en este dispositivo.";
				if (error.name === "NotAllowedError") {
					// user denied camera access permisson
				} else if (error.name === "NotFoundError") {
					// no suitable camera device installed
				} else if (error.name === "NotSupportedError") {
					// page is not served over HTTPS (or localhost)
				} else if (error.name === "NotReadableError") {
					// maybe camera is already in use
				} else if (error.name === "OverconstrainedError") {
					// did you requested the front camera although there is none?
				} else if (error.name === "StreamApiNotSupportedError") {
					// browser seems to be lacking features
				}

				console.log("hubo error", error, error.name);
				f7.dialog.alert(error.name, error.code || "error");
			} finally {
				// hide loading indicator
				this.scannerOpened = true;
				this.cargandoScanner = false;
				this.clp_camperm = true;
				localStorage.setItem("clp_camperm", true);
			}
		},
		async onDecode(decodedString) {
			let url = new URL(decodedString);
			let a = url.searchParams.get("a");
			let b = url.searchParams.get("b");
			let c = url.searchParams.get("c");
			let f = url.searchParams.get("f");
			let w = url.searchParams.get("w");
			let ext = url.searchParams.get("ext");
			let tmp = url.searchParams.get("tm");

			let type = url.searchParams.get("type");
			let ser = url.searchParams.get("serial");
			let mrz = url.searchParams.get("mrz");
			let run = url.searchParams.get("RUN");
			let pni = url.searchParams.get("pni");

			if ((a && b) || f || w || (type && ser && mrz && run) || pni) {
				f7.dialog.preloader(this.$t("commons.cargando"));
				const user = firebase.auth().currentUser;
				if (!user) {
					await firebase.auth().signInAnonymously();
				}
				this.scannerOpened = false;
				await this.llamaLegacy({
					a,
					b,
					c,
					f,
					w,
					ext,
					type,
					ser,
					mrz,
					run,
					tmp,
					pni,
				});
			}
		},
	},
};
</script>
<style>
.mylogin.page {
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center;
	/* transition: background 0.5s linear; */
	transition: background-image 0.5s linear;
}
.mylogin.back1.page {
	background-image: url("../static/heros/loginback_1.jpg");
}

.bannerxsup {
	background: #d4faff;
	font-size: 12px;
}

.latoolbar .dedos {
	height: 100%;
	max-width: 100%;
}
.latoolbar.toolbar {
	height: 100px !important;
	background: #fff !important;
}
.latoolbar .toolbar-inner {
	justify-content: center !important;
}
.suparmargtop {
	margin-top: 100px;
}
a.version {
	font-size: 13px;
	color: #55b6c1 !important;
}
.forza_margentop {
	margin-top: 35vh !important;
}
</style>
