// Import Vue
import { createApp } from "vue";

// Import Framework7
import Framework7 from "framework7/lite-bundle";

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from "framework7-vue/bundle";

// Import Framework7 Styles
import "framework7/framework7-bundle.css";

// Import Icons and App Custom Styles
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";

import "../css/app.css";

// Import App Component
import App from "../components/app.vue";

//custom hicapps
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/auth";

const isForcingProduction = process.env.NODE_ENV === "production" && process.env.FORCE_PRODUCTION === "true";

//init firebase
const firebaseConfig = {
	apiKey: isForcingProduction ? process.env.FIREBASE_API_KEY_PRODUCTION_FORCED : process.env.FIREBASE_API_KEY,
	authDomain: process.env.FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.FIREBASE_DATABASE_URL,
	projectId: process.env.FIREBASE_PROJECT_ID,
	storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.FIREBASE_APP_ID,
	measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);
const defaultAnalytics = firebase.analytics();

if (process.env.USE_FUNCTIONS_EMULATORS === "true") {
	const host = process.env.FUNCTIONS_EMULATOR_HOST;
	const port = parseInt(process.env.FUNCTIONS_EMULATOR_PORT);

	firebase.functions().useEmulator(host, port);
}

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faThumbsUp,
	faExclamationCircle,
	faGlobeAmericas,
	faHandPaper,
	faSyringe,
	faCalendarWeek,
	faQrcode,
	faCheckCircle,
	faQuestionCircle,
	faClipboardCheck,
	faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas, fab);

library.add(
	faThumbsUp,
	faSyringe,
	faCalendarWeek,
	faClipboardCheck,
	faGlobeAmericas,
	faExclamationCircle,
	faHandPaper,
	faQrcode,
	faCheckCircle,
	faQuestionCircle,
	faMinusCircle
);
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);

// Register Framework7 Vue components
registerComponents(app);

import i18n from "../i18n/index.js";
app.use(i18n);

// Mount the app
app.mount("#app");
