<template>
    <div class="locale-changer">
        <f7-list class="no-bg" no-hairlines>
            <f7-list-input
                type="select"
                placeholder="placeholder"
                :value="$i18n.locale"
                @input="setLocale($event.target.value)"
            >
                <template #media>
                    <font-awesome-icon class="fa-2x" :icon="['fas', 'language']" color="white" />
                </template>
                <option
                    class="color-black"
                    :value="locale.code"
                    v-for="locale in locales"
                    :key="locale.code"
                >
                    {{ locale.name }}
                </option>
            </f7-list-input>
        </f7-list>
    </div>
</template>
<script>
import localforage from "localforage";
import { getSupportedLocales } from "@/i18n/index.js";

export default {
    name: "locale-changer",
    data() {
        return {
            locales: getSupportedLocales(),
        };
    },
    methods: {
        setLocale(locale) {
            localforage
                .setItem("locale", locale)
                .then(() => {
                    return localforage.getItem('locale');
                })
                .then(value => {
                    this.$i18n.locale = value;
                });
        }
    }
};
</script>

<style scoped>
.no-bg {
    --f7-list-bg-color: transparent;
    --f7-input-text-color: white;
    --f7-list-item-border-color: white;
}

.color-black {
    color: black;
}
</style>
