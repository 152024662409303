import { createI18n } from "vue-i18n";

const supportedLocales = {
    ar: "العربية",
    de: "Deutsch",
    en: "English",
    es: "Español",
    fr: "Francais",
    hi: "हिन्दी, हिंदी",
    he: "עברית",
    it: "Italiano",
    ja: "日本語 (にほんご)",
    ko: "한국어",
    nl: "Nederlands",
    pl: "język polski",
    pt: "Português",
    ru: "русский",
    zh: "中文"
};

export function getSupportedLocales() {
    let annotatedLocales = []
  
    for (const code of Object.keys(supportedLocales)) {
      annotatedLocales.push({
        code,
        name: supportedLocales[code]
      })
    }
  
    return annotatedLocales;
}

function loadLocaleMessages() {
    const locales = require.context("./", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function getStartingLocale() {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });

    if (supportedLocalesInclude(browserLocale)) {
        return browserLocale;
    } else {
        return "es";
    }
}

function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false };

    const opt = { ...defaultOptions, ...options };

    const navigatorLocale =
        navigator.languages !== undefined
            ? navigator.languages[0]
            : navigator.language;

    if (!navigatorLocale) {
        return undefined;
    }

    const trimmedLocale = opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim();

    return trimmedLocale;
}

function supportedLocalesInclude(locale) {
    return Object.keys(supportedLocales).includes(locale);
}

export default new createI18n({
    locale: getStartingLocale(),
    fallbackLocale: "es",
    messages: loadLocaleMessages(),
});
