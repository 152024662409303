
import { createStore } from 'framework7/lite';

const store = createStore({
  state: {
    resultado: {},
    cargando: true
  },
  getters: {
    resultado({ state }) {
      return state.resultado;
    },

  },
  actions: {
    setResult({ state }, neoresultado) {
      state.resultado = Object.assign({},neoresultado);
    }
  },
})
export default store;
