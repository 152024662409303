<template>
    <f7-app v-bind="f7params">
        <!-- Your main view, should have "view-main" class -->
        <f7-view main class="safe-areas" url="/"></f7-view>
    </f7-app>
</template>
<script>
import { f7ready } from "framework7-vue";
import localforage from "localforage";

import routes from "../js/routes.js";
import store from "../js/store";

export default {
    data() {
        return {
            f7params: {
                name: "Scan", // App name
                theme: "md", // Automatic theme detection
                // App store
                store: store,
                // App routes
                routes: routes,
            },
        };
    },
    beforeMount() {
        localforage.getItem("locale")
            .then(locale => {
                if (locale) {
                    this.$i18n.locale = locale;
                }
            })
            .catch(err => {
                console.error(err)
            })
    },
    mounted() {
        f7ready((f7) => {});
    },
    methods: {},
};
</script>
